<template>
  <b-container fluid>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col lg="12">
            <iq-card body-class="p-0">
              <template v-slot:body>
                <div class="iq-edit-list">
                  <tab-nav :pills="true" class="iq-edit-profile d-flex">
                    <tab-nav-items class="col-md-3 p-0" :active="true" href="#personal-information" title="Personal Information" />
                  </tab-nav>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="12">
            <div class="iq-edit-list-data">
              <tab-content id="pills-tabContent-2">
                <tab-content-item :active="true" id="personal-information" >
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('employee.personal_info') }}</h4>
                    </template>
                    <template v-slot:body>
                      <b-form @submit.prevent="savePersonalInformation(user)">
                        <b-form-group class="row align-items-center">
                          <b-col md="12">
                            <div class="profile-img-edit">
                              <b-img :src="user.logo" class="height-150" alt="profile-pic"/>
                              <input type="hidden" v-model="user.logo">
                              <div class="p-image">
                                <div class="position-relative">
                                  <i class="ri-pencil-line upload-button">
                                    <input type="file" ref="file" @change="previewImage(user)" class="h-100 position-absolute" accept="image/*" style="opacity: 0; left: 0" />
                                  </i>
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-form-group>
                        <b-row align-v="center">
                          <b-form-group class="col-md-6" :label="$t('employee.first_name')" label-for="fname">
                            <ValidationProvider name="First name" rules="required" v-slot="{ errors }">
                              <b-form-input v-model="user.fname" type="text" :placeholder="$t('employee.first_name')" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                              <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                          <b-form-group class="col-md-6" :label="$t('employee.last_name')" label-for="lname">
                            <ValidationProvider name="Last name" rules="required" v-slot="{ errors }">
                              <b-form-input v-model="user.lname" type="text" :placeholder="$t('employee.last_name')" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                              <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </b-form-group>

                          <b-form-group class="col-sm-6" :label="$t('employee.dob')" label-for="dob">
                            <b-form-input type="date" v-model="user.dob" id="dob" value="12-03-2021"></b-form-input>
                          </b-form-group>
                          <b-form-group class="col-sm-6" :label="$t('employee.gender')" label-class="d-block">
                            <b-form-radio inline v-model="user.gender" value="m">{{ $t('employee.male') }}</b-form-radio>
                            <b-form-radio inline v-model="user.gender" value="f">{{ $t('employee.female') }}</b-form-radio>
                          </b-form-group>
                          <b-form-group class="col-sm-6">
                            <label for="contact_no">{{ $t('employee.contact_no') }}:</label>
                            <b-form-input id="contact_no" type="text" v-model="user.phone"></b-form-input>
                          </b-form-group>
                          <b-form-group class="col-sm-6">
                            <label for="email">{{ $t('employee.email') }}:</label>
                            <b-form-input id="email" type="text" v-model="user.email"></b-form-input>
                          </b-form-group>
                          <b-form-group class="col-sm-6">
                            <label for="url">{{ $t('employee.url') }}:</label>
                            <b-form-input id="url" type="text" v-model="user.url"></b-form-input>
                          </b-form-group>
                          <b-form-group class="col-sm-6">
                            <label for="colopicker">{{ $t('employee.color') }}:</label>
                            <b-form-input id="colopicker" type="color" v-model="user.color"></b-form-input>
                          </b-form-group>
                          <b-form-group class="col-sm-12" :label="$t('employee.about')">
                            <b-form-textarea name="about" v-model="user.description" style="line-height: 22px;" rows="5">
                            </b-form-textarea>
                          </b-form-group>

                          <b-form-group class="col-sm-12 mt-4" :label="$t('employee.password')">
                            <b-form-input id="password" type="password" v-model="user.password"></b-form-input>
                          </b-form-group>

                        </b-row>
                        <b-button type="submit" variant="primary" class="mr-2" >{{ $t('employee.save') }}</b-button>
                      </b-form>
                    </template>
                  </iq-card>
                </tab-content-item>
              </tab-content>
            </div>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'

export default {
  name: 'ProfileEdit',
  mounted () {
    core.index()
  },
  data () {
    return {
      apiToken: localStorage.getItem('api_token') || '',
      companyToken: localStorage.getItem('company_token') || '',
      user: {
        fname: '',
        lname: '',
        name: '',
        username: '',
        email: '',
        password: '',
        address1: '',
        address2: '',
        company_name: '',
        logo: require('../../assets/images/user/employee_placeholder.png'),
        phone: '',
        role: '',
        gender: '',
        dob: '',
        url: ''
      }
    }
  },
  methods: {
    savePersonalInformation (item) {
      if (item.fname === '') {
        core.showSnackbar('error', this.$t('feature.missing_employee_fname'))
      } else if (item.lname === '') {
        core.showSnackbar('error', this.$t('feature.missing_employee_lname'))
      } else if (item.phone === '') {
        core.showSnackbar('error', this.$t('feature.missing_employee_phone'))
      } else if (item.email === '') {
        core.showSnackbar('error', this.$t('feature.missing_employee_email'))
      } else if (item.password === '') {
        core.showSnackbar('error', this.$t('feature.missing_employee_password'))
      } else {
        const formData = new FormData()
        formData.append('company', this.companyToken)
        formData.append('saveType', 'personalInformation')
        if (this.$refs.file.files[0]) {
          formData.append('file', this.$refs.file.files[0])
        }
        formData.append('employee', null)
        formData.append('fname', item.fname)
        formData.append('lname', item.lname)
        formData.append('dob', item.dob)
        formData.append('gender', item.gender)
        formData.append('phone', item.phone)
        formData.append('email', item.email)
        formData.append('url', item.url)
        formData.append('color', item.color)
        formData.append('description', item.description)
        formData.append('password', item.password)
        Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
        Axios.post(API.API_ADD_EMPLOYEE, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then((response) => {
            if (response.data.status === 'success') {
              this.$router.push('/employee/profile-edit/' + response.data.token + '#success')
            } else if (response.data.status === 'fail') {
              core.showSnackbar('error', this.$t('feature.email_exists'))
            } else if (response.data.status === 'upgrade') {
              this.$emit('upgrade_message', response.data.nextPlan)
              this.$bvModal.show('globalUpgrade')
            }
          })
          .catch((error) => {
            console.error(error.response)
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
          })
      }
    },
    previewImage: function (task) {
      const reader = new FileReader()
      reader.onload = (e) => {
        task.logo = e.target.result
      }
      reader.readAsDataURL(this.$refs.file.files[0])
    }
  }
}
</script>
